<template>
  <div class="empty-box">
    <div class="empty-box__title">{{ title }}</div>
    <div class="empty-box__subtitle">{{ subtitle }}</div>
    <div class="empty-box__icon">
      <atomic-icon-colored :id="iconId" />
    </div>
    <div class="empty-box__btn">
      <button-base type="primary" size="sm" @click="onClickBtn">{{ btnText }}</button-base>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useColorModeLogic } from '@skeleton/composables/useColorModeLogic';

  const props = withDefaults(
    defineProps<{
      title: string;
      subtitle: string;
      link?: string;
      btnText?: string;
    }>(),
    {
      title: '',
      subtitle: '',
      link: '/',
      btnText: 'Home',
    }
  );

  const { getColorMode } = useColorModeLogic();
  const iconId = computed(() => (getColorMode() === 'dark' ? 'empty-box-dark' : 'empty-box'));

  const emit = defineEmits(['clickLink']);

  const onClickBtn = () => {
    emit('clickLink');
    navigateTo(props.link);
  };
</script>

<style lang="scss" src="~/assets/styles/components/atomic/empty-box.scss" />
